import React, {useState} from 'react';
import {nextPlayer} from './../../../utilities/nextPlayer';
import {selectCard} from './../../../utilities/selectCard';
import Card from './Card';

const Board = ({game, setGame, roundIsOver, setRoundIsOver, setGameOver, eliminatedCards, setEliminatedCards}) => {

    // const [eliminatedCards, setEliminatedCards] = useState(1);
    const [remainingCards, setRemainingCards] = useState(game.cards.length);


    return (
        <div className='board'>
            {
                game.cards.map((card, index) => {
                    return (
                        <Card key={index} card={card} isTarget={card.id === game.targetCard.id ? true : false} 
                        game={game} setGame={setGame} 
                        eliminatedCards={eliminatedCards} setEliminatedCards={setEliminatedCards}
                        remainingCards={remainingCards} setRemainingCards={setRemainingCards}
                        roundIsOver={roundIsOver} setRoundIsOver={setRoundIsOver}
                        setGameOver={setGameOver}  />
                    )
                })
            }
        </div>
    );
}
 
export default Board;