import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import createGame from '../../../utilities/createGame';
import { GameContext } from '../../../contexts/GameContext';


const FormNewGame = ({setDisplayRules}) => {

    const cards = useContext(GameContext).cardsInTheBox;
    const males = useContext(GameContext).arrayMales;
    const females = useContext(GameContext).arrayFemales;

    const navigate = useNavigate();

    const [player1, setPlayer1] = useState("");
    const [player2, setPlayer2] = useState("");
    const [player3, setPlayer3] = useState("");
    const [player4, setPlayer4] = useState("");
    const [player5, setPlayer5] = useState("");
    const [player6, setPlayer6] = useState("");
    const [player7, setPlayer7] = useState("");
    const [player8, setPlayer8] = useState("");

    const [selectInput, setSelectInput] = useState("20");

    const [numberInputs, setNumberInputs] = useState(3);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const placeholder = "Nom du joueur";

    const addInput = () => {
        setNumberInputs(numberInputs + 1);
    }

    const handleChange = (e) => {
        switch (e.target.name) {
            case "player1": setPlayer1(e.target.value); break;
            case "player2": setPlayer2(e.target.value); break;
            case "player3": setPlayer3(e.target.value); break;
            case "player4": setPlayer4(e.target.value); break;
            case "player5": setPlayer5(e.target.value); break;
            case "player6": setPlayer6(e.target.value); break;
            case "player7": setPlayer7(e.target.value); break;
            case "player8": setPlayer8(e.target.value); break;
            default: break;
        }
    }

    const handleSelect = (e) => {
        e.preventDefault();
        setSelectInput(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        let players = [];
        if (player1 !== "") { players.push({ name: player1 }); }
        if (player2 !== "") { players.push({ name: player2 }); }
        if (player3 !== "") { players.push({ name: player3 }); }
        if (player4 !== "") { players.push({ name: player4 }); }
        if (player5 !== "") { players.push({ name: player5 }); }
        if (player6 !== "") { players.push({ name: player6 }); }
        if (player7 !== "") { players.push({ name: player7 }); }
        if (player8 !== "") { players.push({ name: player8 }); }

        if (players.length >= 3) {
            navigate("/game/" + await createGame(cards, females, males, players, parseInt(selectInput)))
        }
        else {
            setError("Le nombre de joueurs requis n'est pas atteint !");
            setLoading(false);
        }
    }



    return (
        <div className='game-form'>
            <div className='rules-toggle-container'>
            <svg onClick={() => setDisplayRules(true)}
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="rules-toggle">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
            </svg>
            <span onClick={() => setDisplayRules(true)} >Règles du jeu</span>
            </div>

            <form onSubmit={handleSubmit}>
                {
                    error && <p className='error-form'>{error}</p>
                }
                <div className='inputs'>
                    <input type="text" placeholder={placeholder}
                        name="player1" value={player1} onChange={handleChange} />
                    <input type="text" placeholder={placeholder}
                        name="player2" value={player2} onChange={handleChange} />
                    <input type="text" placeholder={placeholder}
                        name="player3" value={player3} onChange={handleChange} />

                    {/* Inputs facultatifs */}
                    {numberInputs >= 4 && <input type="text" placeholder={placeholder}
                        name="player4" value={player4} onChange={handleChange} />}
                    {numberInputs >= 5 && <input type="text" placeholder={placeholder}
                        name="player5" value={player5} onChange={handleChange} />}
                    {numberInputs >= 6 && <input type="text" placeholder={placeholder}
                        name="player6" value={player6} onChange={handleChange} />}
                    {numberInputs >= 7 && <input type="text" placeholder={placeholder}
                        name="player7" value={player7} onChange={handleChange} />}
                    {numberInputs >= 8 && <input type="text" placeholder={placeholder}
                        name="player8" value={player8} onChange={handleChange} />}

                    {
                        numberInputs <= 7 && <button type='button' className='btn-add-player' onClick={addInput}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                        </button>
                    }
                </div>

                <div className='select' >
                    <label>Nombre de cartes:</label>
                    <select onChange={handleSelect} value={selectInput}>
                        <option value="12">12</option>
                        <option value="20">20</option>
                        <option value="24">24</option>
                        <option value="32">32</option>
                    </select>
                </div>

                <div className='submit-container'>
                    <button type='submit'
                        className={!loading ? 'btn-submit' : 'btn-submit loading'}
                        disabled={!loading ? false : true}
                    >Je juge donc je suis !</button>
                </div>
            </form>
        </div>
    );
}

export default FormNewGame;