import React, { useState } from 'react';
import Rules from '../game/Rules';
import FormNewGame from '../components/home/FormNewGame';

const Home = () => {
    const [displayRules, setDisplayRules] = useState(false);

    return (
        <>
            {
                !displayRules ?
                    <FormNewGame setDisplayRules={setDisplayRules} />
                :
                    <Rules setDisplayRules={setDisplayRules} />
            }
            
            
        </>
        
    );
}
 
export default Home;