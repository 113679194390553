import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PlayerCard from './PlayerCard';
import { nextPlayer } from '../../../utilities/nextPlayer';
import createGame from './../../../utilities/createGame';
import { GameContext } from '../../../contexts/GameContext';
import shuffleArray from '../../../utilities/shuffleArray';

const Players = ({
    game, setGame,
    gameOver, setGameOver,
    roundIsOver, setRoundIsOver,
    eliminatedCards, setEliminatedCards,
}) => {

    const navigate = useNavigate();
    const cards = useContext(GameContext).cardsInTheBox;
    const males = useContext(GameContext).arrayMales;
    const females = useContext(GameContext).arrayFemales;
    const changeCards = useContext(GameContext).changeCards;

    // const [eliminatedCards, setEliminatedCards] = useState(1);

    const [displayMystery, setDisplayMystery] = useState(false);
    const cardsInTheGame = cards;
    console.log("PLAYERS Component - init cards", cardsInTheGame)
    

    const clicToggleFunction = async () => {
        if (roundIsOver) {
            // await changeCards();
            shuffleArray(cardsInTheGame)
            console.log("PLAYERS Component - after changing cards", cardsInTheGame)

            navigate("/game/" + await createGame(cards, females, males, game.players, game.cards.length, game.master, game.turn))
            setRoundIsOver(false);
            setDisplayMystery(false);
            setEliminatedCards(1);
        } else {
            setEliminatedCards(1);
            nextPlayer(game, setGame, setEliminatedCards);
        }
    }

    const newGame = async () => {
        let players = [];
        game.players.forEach(player => {
            players.push({ name: player.name });
        });

        setRoundIsOver(false)
        setGameOver(false)
        setDisplayMystery(false)

        // await changeCards();
        shuffleArray(cardsInTheGame)

        navigate("/game/" + await createGame(cards, females, males, players, game.cards.length))
    }


    return (
        <>
            {
                game.players && game.players.length > 0 &&
                <div className='players'>
                    <div className='left-part'>
                        {/* Maître du jeu */}
                        <div className='masters-container'>
                            <PlayerCard player={game.players[game.master]}
                                isMaster={true} isCurrentPlayer={false} targetCard={game.targetCard}
                                displayMystery={displayMystery} setDisplayMystery={setDisplayMystery}
                            />
                        </div>

                        {/* Joueurs */}
                        <div className='players-container'>
                            {
                                game.players.filter(player => player.id !== game.players[game.master].id)
                                    .map((player, index) => {
                                        return (
                                            <PlayerCard key={index} player={player} isMaster={false}
                                                isCurrentPlayer={player.id === game.players[game.currentPlayer].id ? true : false}
                                            />
                                        )
                                    })
                            }
                        </div>
                    </div>


                    {
                        !gameOver ?
                            !roundIsOver ?
                                eliminatedCards >= 2 &&
                                <div className='turns-container' onClick={clicToggleFunction}>
                                    <p>Joueur suivant !</p>
                                    <img src="/img/arrow.png" alt="" className='arrow-next' />
                                </div>
                                :
                                // Le round est terminé
                                <div className='turns-container' onClick={clicToggleFunction}>
                                    <p>Manche suivante !</p>
                                    <img src="/img/arrow.png" alt="" className='arrow-next' />
                                </div>
                            :
                            // La partie est complètement terminée
                            <div className='turns-container' onClick={newGame} >
                                <p>Finito !</p>
                                <p>On la remet ?</p>
                            </div>
                    }

                </div>
            }
        </>
    );
}

export default Players;