import { v4 as uuidv4 } from 'uuid';
import shuffleArray from './shuffleArray';
import db from '../firebase/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';

const createGame = async (cardsInTheGame, arrayFemales, arrayMales, players, numberOfCards, indexMasterOfTheGame = 0, turn = 0) => {
    // Data Joueurs
    let newPlayers = [];
    players.forEach(player => {
        newPlayers.push({
            ...player,
            score: 0,
            roundsWin: player.roundsWin ? player.roundsWin : 0,
            id: player.id ? player.id : uuidv4(),
        })
    })

    // Data Cartes
    const cardsToUseInThisGame = cardsInTheGame.slice(0, numberOfCards);
    shuffleArray(arrayMales);
    shuffleArray(arrayFemales);
    cardsToUseInThisGame.forEach((item, index) => {
        item.target = false;
        item.id = index;
        item.name = item.male ? arrayMales[index].name : arrayFemales[index].name
    })


    

    // Data Target
    let index = Math.floor(Math.random() * numberOfCards);
    
    let target = cardsToUseInThisGame[index];
    // console.log(target); return
    cardsToUseInThisGame[index].target = true;

    // Data Master Of The Game
    let indexMaster;
    if (indexMasterOfTheGame === newPlayers.length - 1) {
        indexMaster = 0;
    } else {
        indexMaster = indexMasterOfTheGame + 1;
    }
    

    // Data First Player
    let indexFirstPlayer;
    if(indexMaster === newPlayers.length - 1) {
        indexFirstPlayer = 0;
    } else {
        indexFirstPlayer = indexMaster + 1;
    }
    
    // Turn
    let newTurn = turn + 1;
    
    // Create Game
    const docRef = await addDoc(collection(db, "games"), {
        cards: cardsToUseInThisGame,
        targetCard: target,
        players: newPlayers,
        turn: newTurn,
        maxTurn: players.length,
        master: indexMaster,
        currentPlayer: indexFirstPlayer,
    });

    return docRef.id;

}

export default createGame;