import React, {useState} from 'react';
import Card from './Card';

const Mystery = ({card, displayMystery, setDisplayMystery}) => {
    

    return (
        <div className='bottom-game'>
            { 
                
                <span className='btn-mystery' type='button' onClick={() => setDisplayMystery(!displayMystery)}>
                    {
                        !displayMystery ? <span className=''>?</span> :
                        <Card card={card} isMysteryCard={true} />
                    }
                </span> 
                
            }
        </div>
    );
}
 
export default Mystery;