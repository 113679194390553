import React, {useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import db from '../../firebase/firebaseConfig';
import {doc, getDoc} from 'firebase/firestore';
import Board from '../components/game/Board';
import Players from '../components/game/Players';

const Game = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const [game, setGame] = useState();
    const [roundIsOver, setRoundIsOver] = useState(false);
    const [gameOver, setGameOver] = useState(false);

    const [eliminatedCards, setEliminatedCards] = useState(1);

    useEffect(() => {
        const getGameDetails = async () => {
            const docRef = doc(db, "games", id);
            const docSnap = await getDoc(docRef);

            if (!docSnap.data()) {
                navigate('/404-page-not-found');
            }
            else {
                setGame(docSnap.data());
            }
        }

        getGameDetails();
    }, [id, navigate]);

    return (
        <div className='game-container'>
            {
                game &&
                <>
                    <Board game={game} setGame={setGame}
                    roundIsOver={roundIsOver} setRoundIsOver={setRoundIsOver} 
                    setGameOver={setGameOver}
                    eliminatedCards={eliminatedCards} setEliminatedCards={setEliminatedCards} />

                    <Players game={game} setGame={setGame} 
                    gameOver={gameOver} setGameOver={setGameOver}
                    roundIsOver={roundIsOver} setRoundIsOver={setRoundIsOver} 
                    eliminatedCards={eliminatedCards} setEliminatedCards={setEliminatedCards} />
                </>
            }
        </div>
    );
}
 
export default Game;
