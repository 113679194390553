import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { GameProvider } from './contexts/GameContext';
import './assets/css/app.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GameProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </GameProvider>
);
