import React, {useEffect, useState} from 'react';
import { collection, query, where, getDocs } from "firebase/firestore";
import db from './../firebase/firebaseConfig';
import { v4 as uuidv4 } from 'uuid';
import shuffleArray from '../utilities/shuffleArray';


const GameContext = React.createContext();

const GameProvider = ({children}) => {

    const [cardsInTheBox, setCardsInTheBox] = useState();
    const [arrayFemales, setArrayFemales] = useState();
    const [arrayMales, setArrayMales] = useState();

    const getCards = async() => {
        const colRef = collection(db, "personnages");
        const docsSnap = await getDocs(colRef);
        let cards = [];
        
        docsSnap.forEach((document, index) => {
            cards.push(
                {
                    ...document.data(),
                    id: index,
                    display: true,
                }
            )
        })
        shuffleArray(cards);
        // 
        // console.log(cards);

        setCardsInTheBox(cards);
    }

    const getFemaleIdentities = async() => {
        const colRef = query(collection(db, "identités"), where('isMale', "==", false));
        const docsSnap = await getDocs(colRef);
        let females = [];
        
        docsSnap.forEach((document) => {
            females.push(
                {
                    ...document.data(),
                }
            )
        })
        setArrayFemales(females);
    }

    const getMaleIdentities = async() => {
        const colRef = query(collection(db, "identités"), where('isMale', "==", true));
        const docsSnap = await getDocs(colRef);
        let males = [];
        
        docsSnap.forEach((document) => {
            males.push(
                {
                    ...document.data(),
                }
            )
        })
        setArrayMales(males);
    }

    useEffect(() => {
        getCards();
        getFemaleIdentities();
        getMaleIdentities();
    }, []);

    return(
        <GameContext.Provider value={{ 
            cardsInTheBox: cardsInTheBox,
            arrayFemales: arrayFemales,
            arrayMales: arrayMales,
            changeCards: getCards,
         }}>
            {children}
        </GameContext.Provider>
    )
}

export { GameContext, GameProvider }