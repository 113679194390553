import React from 'react';
import Mystery from './Mystery';

const PlayerCard = ({
    player, isMaster, isCurrentPlayer,
    targetCard, 
    displayMystery, setDisplayMystery
    
}) => {
    return (
        <div 
            className={
                isMaster ? "player master" :
                isCurrentPlayer ? "player current" : "player"
            }>
                <p className='player-name'>{player.name} 
                {isMaster && <span>(master)</span>}
                </p>
                <span className='player-score'>
                {
                    !isMaster ? 
                    player.score : 
                    <Mystery card={targetCard} displayMystery={displayMystery} setDisplayMystery={setDisplayMystery} />
                }</span>
                {player.roundsWin > 0 && <span className='player-round'>{player.roundsWin}</span>}
            </div>
    );
}
 
export default PlayerCard;