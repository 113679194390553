import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { GameContext } from "./contexts/GameContext";
import createGame from "./utilities/createGame";
import Home from "./pages/home/Home";
import Error404 from "./pages/Error404/Error404";
import Game from './pages/game/Game';
import NavBar from "./components/navbar/Navbar";

function App() {

  const { cardsInTheBox, changeCards, arrayFemales, arrayMales } = useContext(GameContext);

  const lauchNewGame = async () => {
    // await changeCards();
    createGame(cardsInTheBox, arrayFemales, arrayMales, ['pierre', 'Jo', 'Alain'], 20);
  }


  return (
    <div className="app">
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/game/:id" element={<Game />} />

        <Route path="*" element={<Error404 />} />
      </Routes>
    </div>
  );
}

export default App;
