const nextPlayer = (game, setGame, setEliminatedCards) => {
    let nextPlayer;
    

    if (game.currentPlayer + 1 < game.players.length) {
        nextPlayer = game.currentPlayer + 1
        if (nextPlayer === game.master) {
            nextPlayer++;
            if (nextPlayer === game.players.length) {
                nextPlayer = 0;
            }
        }
    }
    else {
        nextPlayer = 0;
        if (nextPlayer === game.master) {
            nextPlayer++;
        }
    }

    setGame(existingValues => ({
        ...existingValues,
        currentPlayer: nextPlayer
    }))

    setEliminatedCards(1)
}

export { nextPlayer };