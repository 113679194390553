import React from 'react';
import { selectCard } from '../../../utilities/selectCard';

const Card = ({
    card, isTarget, isMysteryCard = false,
    game, setGame, setGameOver,
    eliminatedCards, setEliminatedCards, 
    roundIsOver, setRoundIsOver,
    remainingCards, setRemainingCards
}) => {
    return (
        <div 
        className={
            isMysteryCard ? "mysteryCard card" :
            card.display ? "card" : "card invisible"}>
            <img src={card.path} className="card-on-board" alt={card.name} />
            <div className="card-name">{card.name}</div>
            {
                !isMysteryCard &&
                !roundIsOver &&
                <div className='actions'>
                    <div className='action action-false'
                    onClick={() => selectCard(
                        card, isTarget, eliminatedCards, 
                        game, setGame, setEliminatedCards, 
                        remainingCards, setRemainingCards, 
                        // roundIsOver, setRoundIsOver, 
                        setRoundIsOver,
                        setGameOver)} 
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3.5" stroke="white">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
            }
        </div>
    );
}
 
export default Card;