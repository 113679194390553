import React, {useState} from 'react';
import { Link } from 'react-router-dom';



const NavBar = () => {

    
    const toggleFullScreen = () => {
        if(window.fullScreen) {
            document.exitFullscreen();
        }
        else {
            document.getElementById('body').requestFullscreen();
        }
    }
    

    return (
        <header>
            <Link to="/">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" className="home-svg">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
            </svg>
            </Link>

            <Link to="/"><h1 className="title">Qu'est-ce qu'elle a ma <span style={{ whiteSpace: "nowrap" }}>gueule ?!</span></h1></Link>

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" className="fullscreen-svg"
            onClick={toggleFullScreen} >
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
            </svg>

        </header>
    );
}

export default NavBar;