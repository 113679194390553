import { nextPlayer } from "./nextPlayer";

const selectCard = async(card, isTarget, eliminatedCards, game, setGame, setEliminatedCards, remainingCards, setRemainingCards, setRoundIsOver, setGameOver) => {
    let { players, cards, turn } = game;

    // Si la carte sélectionnée n'est pas la carte mystère
    if (!isTarget) {

        // on réinitialise la classe change au body
        document.getElementById("body").classList.remove('change');

        // Une carte supplémentaire a été éliminée par un joueur et il reste donc 1 carte de moins sur le plateau

        // On élimine la carte du plateau
        cards.filter(selectedCard => selectedCard.id === card.id)
        .forEach(selectedCard => {
            selectedCard.display = false;
        })

        // On donne 1 point au joueur en cours
        players[game.currentPlayer].score++;

        // Si il ne reste plus qu'une carte sur la plateau, la manche est terminée
        if(remainingCards === 2) {
            // On donne une manche au joueur en cours
            players[game.currentPlayer].roundsWin++;

            // On donne une manche au maitre du jeu
            players[game.master].roundsWin++;

            // Si des joueurs ont un score plus élevé que le joueur en cours, On donne une manche aux joueurs ayant le score le plus élevé, à l'exception du maître du jeu et du joueur en cours
            let maxScore = players[game.currentPlayer].score;
            players
            .filter(player => player.id !== players[game.currentPlayer].id && player.id !== players[game.master].id)
            .forEach(player => {
                maxScore = player.score > maxScore ? player.score : maxScore;
            })

            players
            .filter(player => player.id !== players[game.currentPlayer].id && player.id !== players[game.master].id && player.score === maxScore)
            .forEach(player => {
                player.roundsWin++;
            })

            // La manche est terminée
            console.log("la manche est terminée");
            console.log("La partie est-elle terminée ?", turn === game.maxTurn ? "OUI" : "NON");
            if (turn === game.maxTurn) {
                // La partie est terminée
                setGameOver(true);
            } else {
                setRoundIsOver(true)
                setRemainingCards(cards.length)
            }
            
        }

        // Sinon il reste plusieurs cartes sur le plateau, et la manche continue
        else {
            // Si le joueur a déjà éliminé 3 cartes, on passe automatiquement la main au joueur suivant
            if (eliminatedCards === 3) {
                
                // Next Player
                nextPlayer(game, setGame, setEliminatedCards);

                // On change temporairement la couleur du background pour indiquer le chgt de joueur
                document.getElementById("body").classList.add('change');
                
            }

            // Sinon on autorise le joueur a passer son tour (il peut aussi continuer à éliminer des cartes)
            else {
                // on ajoute 1 carte au compte des cartes éliminées par le joueur en cours
                setEliminatedCards(eliminatedCards + 1)
                
            }
            

            // On enlève une carte au compte des cartes restantes sur le plateau
            setRemainingCards(remainingCards - 1)
            
        }
    }


    // Sinon la carte sélectionnée est la carte mystère
    else {

        // Toutes les cartes disparaissent excepté la carte mystère
        cards.filter(item => !item.target )
        .forEach(item => {
            item.display = false;
        })

        // Les joueurs ayant le score le plus élevé gagnent une manche, à l'exception du maître du jeu et du joueur en cours qui a découvert la mauvaise carte
        
        let maxScore = 0;
        players
        .filter(player => player.id !== players[game.master].id && player.id !== players[game.currentPlayer].id)
        .forEach(player => {
            maxScore = player.score > maxScore ? player.score : maxScore;
        })

        players
        .filter(player => player.id !== players[game.master].id && player.id !== players[game.currentPlayer].id && player.score === maxScore)
        .forEach(player => {
             player.roundsWin++;
        })


        //  La manche est terminée
        console.log("4. La manche est terminée")
        turn++;
        setRoundIsOver(true);

        // Le nombre de cartes restantes ainsi que le nombre de cartes éliminées par le joueur en cours sont remis à zéro
        
        setRemainingCards(cards.length);
        setEliminatedCards(1);

        // Si il s'agissait de la dernière manche, la partie est terminée
        console.log("Tour suivant: ", turn)
        console.log("La partie est-elle terminée ?", turn > game.maxTurn ? "OUI" : "NON")
        if (turn > game.maxTurn) {
            setGameOver(true);
        }
    }

    // Dans tous les cas, on met à jour GAME
    setGame(existingValues => ({
        ...existingValues,
        players: players,
        cards: cards, 
    }))
}



export {selectCard};