import React from 'react';

import { collection, addDoc, LoadBundleTask } from 'firebase/firestore';
import db from '../../firebase/firebaseConfig';

const addPhotos = async() => {
    const dbRef = collection(db, "personnages");
    for(let i = 0; i < 52; i++) {

        let index = i < 10 ? "0" + i : i;

        const data = {
            male: true,
            path: `/img/hommes-${index}.jpg`
        }

        addDoc(dbRef, data)
        .then(docRef => {
            console.log(docRef.id)
        })
        .catch(error => {
            console.log(error)
        })
    }
}

const males = [
    "Antonin",
    "Augustin",
    "Alban",
    "Antoine",
    "Auguste",
    "Emile",
    "Clement",
    "Enzo",
    "Felix",
    "Gaspard",
    "Julien",
    "Jules",
    "Marin",
    "Marius",
    "Lorenzo",
    "Léo",
    "Lucien",
    "Léon",
    "Lucas",
    "Marceau",
    "Martin",
    "Leonard",
    "Luca",
    "Marcel",
    "Maxime",
    "Quentin",
    "Paul",
    "Pablo",
    "Romeo",
    "Romain",
    "Victor",
    "Valentin",
    "Lino",
    "Lilian",
    "Titouan",
    "Timeo",
    "Anthony",
    "Aubin",
    "Anton",
    "Emilien",
    "Eloi",
    "Cesar",
    "Emilio",
    "Florian",
    "Gaetan",
    "Justin",
    "Marc",
    "Lukas",
    "Max",
    "Marcus",
    "Remi",
    "Paolo",
    "Octave",
    "Roman",
    "Tony",
    "Vincent",
    "Lenzo",
    "Lubin",
    "Antonio",
    "Benoit",
    "Albin",
    "Francois",
    "Florent",
    "Luc",
    "Maximilien",
    "Leonardo",
    "Marco ",
    "Paco",
    "Olivier",
    "Amadou",
    "Calvin",
    "Aime",
    "Christian",
    "Constantin",
    "Cyprien",
    "Fabien",
    "Constant",
    "Gino",
    "Maxim",
    "Luciano",
    "Luke",
    "Oliver",
    "Pio",
    "Remy",
    "Silas",
    "Valentino",
    "Virgile",
    "Viktor",
    "Bastian",
    "Darius",
    "Lelio",
    "Mayeul",
    "Solan",
    "Adeodat",
    "Aguistin",
    "Amandin",
    "Augusto",
    "Augustus",
    "Bibian",
    "Blaisiane",
    "Boniface ",
    "Stanislas",
    "Stan",
    "Stanley",
    "Boris ",
    "Vladimi",
    "Charles",
    "Hugo",
    "Loïs ",
    "Brad",
    "Ronny ",
    "Sullivan",
    "Jean-Yves",
    "Jean-Michel",
    "Pierre-Lou",
]

const females = [
    "Ava",
    "Candice",
    "Albane",
    "Alba",
    "Celia",
    "Emilie",
    "Dina",
    "Clara",
    "Constance",
    "Clemence",
    "Capucine",
    "Clementine",
    "Diane",
    "Giulia",
    "Julie",
    "Julia",
    "Juliette",
    "Laura",
    "Justine",
    "Léonie",
    "Lucie",
    "Marion",
    "Louka",
    "Olivia",
    "Pauline",
    "Stella",
    "Rose",
    "Soline",
    "Romane",
    "Valentine",
    "Victoire",
    "Victoria",
    "Lenny",
    "Joy",
    "Lilou",
    "Amandine",
    "Amy",
    "Augustine",
    "Estelle",
    "Chiara",
    "Claire",
    "Diana",
    "Clarisse",
    "Emilia",
    "Fanny",
    "Flora",
    "June",
    "Hortense",
    "Lucy",
    "Melia",
    "Lucia",
    "Lucile",
    "Paola",
    "Rosalie",
    "Solene",
    "Violette",
    "Camila",
    "Amaya",
    "Ariane",
    "Clelia",
    "Elodie",
    "Celine",
    "Emily",
    "Emeline",
    "Carmen",
    "Fleur",
    "Flavie",
    "Flore",
    "Grace",
    "Laure",
    "Juliana",
    "Juline",
    "Marina",
    "Marine",
    "Luce",
    "Pia",
    "Tania",
    "Rosa",
    "Rosie",
    "Antonia",
    "Cecilia",
    "Ariana",
    "Domitille",
    "Cecile",
    "Florine",
    "Honorine",
    "Laetitia",
    "Leontine",
    "Lucille",
    "Marianne",
    "Paula",
    "Ombeline",
    "Octavia",
    "Sidonie",
    "Youna",
    "Antonine",
    "Aveline",
    "Bea",
    "Beat",
    "Bibiane",
    "Capucino",
    "Amata",
    "Avelina",
    "Beate",
    "Camilla",
    "Aemilia",
    "Aimee ",
"Beatriz",
"Benedicte",
"Biche",
"Blandina",
"Carsta",
"Ceese",
"Celinia",
"Chretienne",
"Christine",
"Claatje",
"Clarine",
"Clementia",
"Cyprille",
"Emilda",
"Celie",
"Cesarie",
"Cesarine",
"Christel",
"Christiana",
"Claro",
"Clarrie",
"Clemmie",
"Constantina ",
"Lola ",
"Milla",
"Mylène",
"Audrey",
"Vera ",
"Véronique",
"Kenza",
"Gwendoline",
"Kelly",
]

const addMales = async() => {
    const dbRef = collection(db, "identités");
    males.forEach(male => {
        const data = { isMale: true, name: male }
        addDoc(dbRef, data);
    })
}

const addFemales = async() => {
    const dbRef = collection(db, "identités");
    females.forEach(female => {
        const data = { isMale: false, name: female }
        addDoc(dbRef, data);
    })
}


const Error404 = () => {
    return (
        <div className='error-404'>
        <h1 className='not-found'>Error 404 - Page not found !</h1>
        {/* <button onClick={addPhotos}>add Photos</button>
        <button onClick={addMales}>prenoms masculins</button>
        <button onClick={addFemales}>prenoms féminins</button> */}
        </div>
    );
}
 
export default Error404;
