import React from 'react';

const Rules = ({ setDisplayRules }) => {
    return (
        <>
            <div className='rules'>
                <h1>Comment on joue ?</h1>

                <div>
                    <p>Tout le monde connaît le "Qui est-ce ?". Eh oui, Robert porte bien une casquette, il est blond, à les yeux marrons et des lunettes. Bravo, j'espère que ce n'était pas trop compliqué !</p>
                    <p>Mais Robert vote-t-il à droite, voire même très à droite ? Ou bien ne vote-t-il pas car il est sous le coup d'une condamnation... Ne serait-il pas d'ailleurs un peu fétichiste des orteils et ne souffre-t-il pas depuis tout petit d'une terrible phobie des araignées ?</p>
                    <p>C'est ce à quoi "Qu'est-ce qu'elle a ma gueule" va tenter de répondre !</p>
                </div>

                <div>
                    <p>Chaque joueur, à son tour, va poser une question subjective au maître du jeu, qui y répondra en son âme et conscience : ton personnage pète-t'il en public sans gêne, ton personnage pourrait-il manifester contre le mariage pour tous ?</p>
                    <p>Tous les jugements sont possibles, il est simplement interdit de se référer au physique ou au sexe du personnage !</p>
                    <p>Suivant les informations obtenues, le joueur pourra alors éliminer entre 1 et 3 cartes du plateau, puis laissera la main au suivant et ce jusqu'à ce que le personnage mystère soit découvert...</p>
                </div>

                <div>
                    <p>Une partie se joue en autant de manches qu'il y a de joueurs, de façon à ce que chacun soit le maître du jeu une fois.</p>
                    <p>Quand un joueur élimine une carte, il marque un point. Si il élimine 3 cartes, il marque donc 3 points.</p>
                    <p>La manche est attribuée au(x) joueur(s) qui a le plus de points lorsque le personnage mystère est découvert.</p>
                    <p>Bien sûr, un joueur qui se trompe en éliminant le personnage mystère ne peut pas remporter la manche, même si il a le score le plus élevé !</p>
                    <p>Enfin, si toutes les cartes sont éliminées du plateau, plusieurs joueurs remportent la manche : le maître du jeu, qui a su guider les joueurs dans leurs jugements, le joueur qui a éliminé la dernière carte et le ou les joueurs qui ont le meilleur score !</p>
                </div>


            </div>

            <div className='submit-container'>
                <button onClick={() => setDisplayRules(false)}
                    type='button' className='btn-submit'>À vos marques, prêts, jugez !</button>
            </div>
        </>
    );
}

export default Rules;